import React from "react"
import { connect } from "react-redux"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { lingua_it } from "../i18n/it-IT"
import { lingua_en } from "../i18n/en-GB"
import { lingua_es } from "../i18n/es-ES"
import { lingua_ca } from "../i18n/es-CA"

import Seo from "../components/seo"

const OurArtisans = props => {
  const { data, lang } = props

  let lingua = {}

  switch (lang) {
    case "it-IT":
      lingua = lingua_it
      break
    case "es-CA":
      lingua = lingua_ca
      break
    case "es-ES":
      lingua = lingua_es
      break
    case "en-GB":
      lingua = lingua_en
      break
    default:
      lingua = lingua_it
  }

  return (
    <div>
      <Seo
        title={lingua.sito.titolo}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={lingua.sito.sottotitolo}
      />
      <section className="section" style={{ background: "#f0f5f6" }}>
        <div className="container">
          <h1 className="title is-size-3-mobile is-2 is-spaced">
            {lingua.footer.ourartisans}
          </h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content is-medium">
                <p className="has-text-weight-bold">
                  {lingua.sito.nostriartigiani.sotto1}
                </p>
                <p>{lingua.sito.nostriartigiani.p1}</p>
                <p>{lingua.sito.nostriartigiani.p2}</p>
                <p>{lingua.sito.nostriartigiani.p3}</p>
                <p>
                  <Img fluid={data.artigiani1.childImageSharp.fluid} />
                </p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t4}</p>
                <p>{lingua.sito.nostriartigiani.p4}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t5}</p>
                <p>{lingua.sito.nostriartigiani.p5}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t6}</p>
                <p>{lingua.sito.nostriartigiani.p6}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t7}</p>
                <p>{lingua.sito.nostriartigiani.p7}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t8}</p>
                <p>{lingua.sito.nostriartigiani.p8}</p>

                <div className="columns">
                  <div className="column">
                    <Img fluid={data.artigiani2.childImageSharp.fluid} />
                  </div>
                  <div className="column">
                    <Img fluid={data.artigiani3.childImageSharp.fluid} />
                  </div>
                </div>

                <p>{lingua.sito.nostriartigiani.p9}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t10}</p>
                <p>{lingua.sito.nostriartigiani.p10}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t11}</p>
                <p>{lingua.sito.nostriartigiani.p11}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t12}</p>
                <p>{lingua.sito.nostriartigiani.p12}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t13}</p>
                <p>{lingua.sito.nostriartigiani.p13}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t14}</p>
                <p>{lingua.sito.nostriartigiani.p14}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t15}</p>
                <p>{lingua.sito.nostriartigiani.p15}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t16}</p>
                <p>{lingua.sito.nostriartigiani.p16}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t17}</p>
                <p>{lingua.sito.nostriartigiani.p17}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t18}</p>
                <p>{lingua.sito.nostriartigiani.p18}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t19}</p>
                <p>{lingua.sito.nostriartigiani.p19}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t20}</p>
                <p>{lingua.sito.nostriartigiani.p20}</p>
                <p className="title is-5">{lingua.sito.nostriartigiani.t21}</p>
                <p>{lingua.sito.nostriartigiani.p12}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(OurArtisans)

export const query = graphql`
  query {
    artigiani1: file(relativePath: { regex: "/sito/artigiani1.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    artigiani2: file(relativePath: { regex: "/sito/artigiani2.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    artigiani3: file(relativePath: { regex: "/sito/artigiani3.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
